@import 'styles/scss/variable.scss';
.AddWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: toRem(24);
  margin-bottom: toRem(35);
  .formContetWrapper {
    width: toRem(800);
    padding: toRem(24);
    background-color: $ContentColor;
    border-radius: toRem(16);
    box-shadow: 0px 5px 10px 10px rgba(22, 22, 22, 0.10);
  }
}