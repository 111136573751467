@import 'styles/scss/variable.scss';
$gray-color: rgba(0, 0, 0, 0.2);
$dark-color: #263238;
$error-color: #e53935;

.image-upload-components {
  background-color: $GrayColor4;
  position: relative;
  padding: toRem(16);
  border-radius: toRem(16);
  .upload_or_line_wrapper {
    display: flex;
    justify-content: center;
    margin-top: toRem(16);
    margin-bottom: toRem(-10);
    .upload_or_line {
      width: toRem(145);
      height: toRem(20);
      box-sizing: border-box;
      // background-color: greenyellow;
      padding-top: toRem(9);
      position: relative;
      &__line {
        height: toRem(1);
        background: $BorderColor;
      }
      &__text {
        position: absolute;
        bottom: toRem(0);
        font-size: toRem(14);
        color: $SecondaryText;
        height: toRem(20);
        background-color: $GrayColor4;
        width: fit-content;
        left: toRem(56);
        padding: 0 toRem(10);
      }
    }
  }
  .image-upload-label {
    // position: absolute;
    // top: -8px;
    // left: 15px;
    // padding: 0 10px;
    margin-bottom: toRem(3);
    font-weight: 700;
    font-size: toRem(12);
    color: $PrimaryText;
  }

  .uploadNewContentWrapper {
    border: toRem(1) dashed $BorderColor;
    padding: toRem(24) toRem(24);
    height: toRem(60);
    background-color: $ContentColor;
  }

  .flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .image-upload-zone-container {
    // border: 1px dashed $BorderColor;
    border-radius: toRem(5);
    padding: toRem(30) toRem(10);
    margin-bottom: toRem(10);

    .upload-icon {
        width: toRem(32);
        height: toRem(32);
    
        path {
          fill: $GrayColor;
        }
    
        margin-bottom: toRem(8);
      }

    &:hover {
      cursor: pointer;
    }
  }

  .thumbs-container {
    .remove-btn {
      background-color: white;
      text-align: center;
      border-radius: 100%;
      font-size: toRem(20);
      position: absolute;
      top: toRem(-10);
      right: toRem(-10);
      &:hover {
        cursor: pointer;
      }
    }
    .image-container {
      position: relative;
      margin: toRem(5);

      &__image {
        max-height: toRem(150);
        max-width: toRem(150);
        border-radius: toRem(7);
      }
    }
  }

  .review-image-container {
    position: relative;
    margin: toRem(15);

    &__remove-btn {
      background-color: white;
      text-align: center;
      border-radius: 100%;
      font-size: toRem(30);
      position: absolute;
      top: toRem(-16);
      right: toRem(-15);
      color: $GrayColor2;
      width: toRem(32);
      height: toRen(32);
      box-sizing: border-box;
      border: toRem(1) solid $BorderColor;
      &:hover {
        cursor: pointer;
      }
      &::before {
        content: '\2715' !important;
        font-size: toRem(25);
        position: relative;
        bottom: toRem(2);
      }
    }

    &__image {
      width: toRem(160);
      height: toRem(160);
      border-radius: toRem(3);
    }
  }

    .image-upload-components-input {
      margin-top: toRem(24);
      input {
        text-align: center;
        background-color: $ContentColor !important;
        border-radius: inherit;
      }
    }
}
