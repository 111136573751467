@import 'styles/scss/variable.scss';
.versionItem {
  .m-b-20 {
    margin-bottom: 20px;
  }
  .header {
    background: $BlueLightColor;
  }
  .version-title {
    color: #468dac;
    font-size: 22px;
    font-weight: 700;
    margin-top: 30px;
  }
  .verion-row {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $BorderColor;
    .verion-name {
      width: 13%;
      border-right: 1px solid $BorderColor;
      padding: toRem(4) toRem(8);
      text-align: center;
      b {
        font-weight: 700;
      }
    }
    .verion-v {
      width: 15%;
      border-right: 1px solid $BorderColor;
      padding: toRem(4) toRem(8);
      text-align: center;
      b {
        font-weight: 700;
      }
    }
    .verion-force {
      width: 8%;
      border-right: 1px solid $BorderColor;
      padding: toRem(4) toRem(8);
      text-align: center;
      b {
        font-weight: 700;
      }
    }
    .verion-create {
      width: 12%;
      padding: toRem(4) toRem(8);
      border-right: 1px solid $BorderColor;
      text-align: center;
      b {
        font-weight: 700;
      }
    }
    .verion-deploy {
      width: 12%;
      padding: toRem(4) toRem(8);
      border-right: 1px solid $BorderColor;
      text-align: center;
      b {
        font-weight: 700;
      }
    }
    .verion-app {
      width: 15%;
      padding: toRem(4) toRem(8);
      b {
        font-weight: 700;
      }
    }
    .verion-platform {
      width: 10%;
      padding: toRem(4) toRem(8);
      border-right: 1px solid $BorderColor;
      b {
        font-weight: 700;
      }
    }
    .verion-env {
      width: 8%;
      padding: toRem(4) toRem(8);
      border-right: 1px solid $BorderColor;
      b {
        font-weight: 700;
      }
    }
    .verion-url {
      width: 28%;
      padding: toRem(4) toRem(8);
      border-right: 1px solid $BorderColor;
      b {
        font-weight: 700;
      }
    }
    .verion-act {
      width: 4%;
      padding: toRem(4) toRem(8);
      border-right: 1px solid $BorderColor;
      display: flex;
      align-items: center;
      b {
        font-weight: 700;
      }
      div {
        cursor: pointer;
      }
      .edit {
        svg {
          color: green;
        }
        margin-right: 10px;
      }
    }
  }
}