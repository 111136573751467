.contentTooltip {

    .coppyWrapper {
        position: relative;
        height: 20px;
        width: 100%;
        margin-top: 10px;
        .copyButton {
            font-size: 12px;
            font-weight: 500;
            padding: 4px 8px;
            border-radius: 4px;
            border: 1px solid;
            border-color: #1E4976;
            color: #E2EDF8;
            background-color: rgba(23, 58, 94, 0.5);
            cursor: pointer;
            position: absolute;
            right: 0;
        }
    }
}