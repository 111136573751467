@import 'styles/scss/variable.scss';

#header {
    // height: $headerHeight;
    background-color: $HeaderBackgroundColor;
    box-shadow: 0 toRem(2) toRem(4) 0 rgba(0, 0, 0, 0.2);
    display: block;
    height: toRem(56);
    box-sizing: border-box;
    position: sticky;
    margin-left: 0;
    .customToolbar {
        height: toRem(56);
        min-height: toRem(56);
    }
    .user-account {
        color: $SecondaryText;
        float: right;
        border-radius: toRem(4);
        line-height: toRem(34);
        padding-left: toRem(16);
        margin: toRem(8) 0px;
        background: $BodyColor;
        span {
            height: toRem(34);
            width: toRem(34);
            display: inline-block;
            text-align: center;
            background-color: #efe8e8;
            margin-left: toRem(7);
            i {
                font-size: toRem(12);
                color: $SecondaryText;
            }
        }
    }
    .headerLogo {
        img {
            width: toRem(156);
            height: auto;
        }
    }
}
.element-expand-first {
    margin-top: 5px !important;
}
.element-expand {
    float: left;
    background: #ffffff;
    box-shadow: 0 toRem(2) toRem(4) 0 rgba(0, 0, 0, 0.24);
    border-radius: toRem(2);
    width: toRem(244);
    padding: 0px toRem(8);
    z-index: 5;
    position: relative;
    overflow: hidden;
    &.hide {
        display: none;
    }
    a {
        float: left;
        margin: toRem(5) 0px;
        font-size: toRem(16);
        color: $SecondaryText;
    }
    .slider-element {
        float: left;
        input[type="text"] {
            margin-top: toRem(-4);
            border-bottom: 0px;
            font-size: toRem(16);
            padding: toRem(8) 0px toRem(2) 0px;
            -moz-appearance: textfield;
        }
    }
}
/*--- END Header ---*/