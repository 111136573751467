@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?11230072');
  src: url('../font/fontello.eot?11230072#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?11230072') format('woff2'),
       url('../font/fontello.woff?11230072') format('woff'),
       url('../font/fontello.ttf?11230072') format('truetype'),
       url('../font/fontello.svg?11230072#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?11230072#fontello') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-admin:before { content: '\e800'; } /* '' */
.icon-user_attributes:before { content: '\e801'; } /* '' */
.icon-music:before { content: '\e802'; } /* '' */
.icon-search:before { content: '\e803'; } /* '' */
.icon-mail:before { content: '\e804'; } /* '' */
.icon-heart:before { content: '\e805'; } /* '' */
.icon-star:before { content: '\e806'; } /* '' */
.icon-user:before { content: '\e807'; } /* '' */
.icon-videocam:before { content: '\e808'; } /* '' */
.icon-camera:before { content: '\e809'; } /* '' */
.icon-photo:before { content: '\e80a'; } /* '' */
.icon-attach:before { content: '\e80b'; } /* '' */
.icon-lock:before { content: '\e80c'; } /* '' */
.icon-eye:before { content: '\e80d'; } /* '' */
.icon-tag:before { content: '\e80e'; } /* '' */
.icon-thumbs-up:before { content: '\e80f'; } /* '' */
.icon-pencil:before { content: '\e810'; } /* '' */
.icon-comment:before { content: '\e811'; } /* '' */
.icon-location:before { content: '\e812'; } /* '' */
.icon-cup:before { content: '\e813'; } /* '' */
.icon-trash:before { content: '\e814'; } /* '' */
.icon-doc:before { content: '\e815'; } /* '' */
.icon-note:before { content: '\e816'; } /* '' */
.icon-cog:before { content: '\e817'; } /* '' */
.icon-params:before { content: '\e818'; } /* '' */
.icon-calendar:before { content: '\e819'; } /* '' */
.icon-sound:before { content: '\e81a'; } /* '' */
.icon-clock:before { content: '\e81b'; } /* '' */
.icon-lightbulb:before { content: '\e81c'; } /* '' */
.icon-tv:before { content: '\e81d'; } /* '' */
.icon-desktop:before { content: '\e81e'; } /* '' */
.icon-mobile:before { content: '\e81f'; } /* '' */
.icon-cd:before { content: '\e820'; } /* '' */
.icon-inbox:before { content: '\e821'; } /* '' */
.icon-globe:before { content: '\e822'; } /* '' */
.icon-cloud:before { content: '\e823'; } /* '' */
.icon-paper-plane:before { content: '\e824'; } /* '' */
.icon-fire:before { content: '\e825'; } /* '' */
.icon-graduation-cap:before { content: '\e826'; } /* '' */
.icon-megaphone:before { content: '\e827'; } /* '' */
.icon-database:before { content: '\e828'; } /* '' */
.icon-key:before { content: '\e829'; } /* '' */
.icon-truck:before { content: '\e82a'; } /* '' */
.icon-money:before { content: '\e82b'; } /* '' */
.icon-food:before { content: '\e82c'; } /* '' */
.icon-shop:before { content: '\e82d'; } /* '' */
.icon-diamond:before { content: '\e82e'; } /* '' */
.icon-t-shirt:before { content: '\e82f'; } /* '' */
.icon-wallet:before { content: '\e830'; } /* '' */
.icon-beaker:before { content: '\e831'; } /* '' */
.icon-remove-1:before { content: '\e832'; } /* '' */
.icon-user-1:before { content: '\e833'; } /* '' */
.icon-user-group:before { content: '\e834'; } /* '' */
.icon-setting:before { content: '\e835'; } /* '' */
.icon-cart:before { content: '\e836'; } /* '' */
.icon-equipment:before { content: '\e837'; } /* '' */
.icon-history:before { content: '\e838'; } /* '' */
.icon-calendar-1:before { content: '\e839'; } /* '' */
.icon-down-open:before { content: '\e83a'; } /* '' */
.icon-left-open:before { content: '\e83b'; } /* '' */
.icon-right-open:before { content: '\e83c'; } /* '' */
.icon-up-open:before { content: '\e83d'; } /* '' */
.icon-down-open-mini:before { content: '\e83e'; } /* '' */
.icon-left-open-mini:before { content: '\e83f'; } /* '' */
.icon-right-open-mini:before { content: '\e840'; } /* '' */
.icon-up-open-mini:before { content: '\e841'; } /* '' */
.icon-down-open-big:before { content: '\e842'; } /* '' */
.icon-left-open-big:before { content: '\e843'; } /* '' */
.icon-right-open-big:before { content: '\e844'; } /* '' */
.icon-up-open-big:before { content: '\e845'; } /* '' */
.icon-down:before { content: '\e846'; } /* '' */
.icon-left:before { content: '\e847'; } /* '' */
.icon-right:before { content: '\e848'; } /* '' */
.icon-up:before { content: '\e849'; } /* '' */
.icon-down-dir:before { content: '\e84a'; } /* '' */
.icon-left-dir:before { content: '\e84b'; } /* '' */
.icon-right-dir:before { content: '\e84c'; } /* '' */
.icon-up-dir:before { content: '\e84d'; } /* '' */
.icon-minus:before { content: '\e84e'; } /* '' */
.icon-plus:before { content: '\e84f'; } /* '' */
.icon-pencil-1:before { content: '\e850'; } /* '' */
.icon-cogs:before { content: '\e851'; } /* '' */
.icon-cog-circled:before { content: '\e852'; } /* '' */
.icon-cog-1:before { content: '\e853'; } /* '' */
.icon-subscription:before { content: '\e854'; } /* '' */
.icon-down-small:before { content: '\e855'; } /* '' */
.icon-left-small:before { content: '\e856'; } /* '' */
.icon-right-small:before { content: '\e857'; } /* '' */
.icon-up-small:before { content: '\e858'; } /* '' */
.icon-cancel:before { content: '\e859'; } /* '' */
.icon-ok:before { content: '\e85a'; } /* '' */
.icon-close:before { content: '\e85b'; } /* '' */
.icon-dashboard:before { content: '\e85c'; } /* '' */
.icon-lock-1:before { content: '\e85d'; } /* '' */
.icon-save:before { content: '\e85e'; } /* '' */
.icon-folder:before { content: '\e85f'; } /* '' */
.icon-search-1:before { content: '\e860'; } /* '' */
.icon-ic_warning_24px:before { content: '\e861'; } /* '' */
.icon-notes:before { content: '\e862'; } /* '' */
.icon-single-01-2:before { content: '\e863'; } /* '' */
.icon-layout-11:before { content: '\e864'; } /* '' */
.icon-ic_more_horiz_48px:before { content: '\e865'; } /* '' */
.icon-c-question:before { content: '\e866'; } /* '' */
.icon-ctrl-left:before { content: '\e867'; } /* '' */
.icon-ic_more_vert:before { content: '\e8a0'; } /* '' */
.icon-users-mm:before { content: '\ea01'; } /* '' */
.icon-e-add:before { content: '\ea02'; } /* '' */
.icon-ic_file_download_48px:before { content: '\ea03'; } /* '' */
.icon-ic_search_24px:before { content: '\ea04'; } /* '' */
.icon-row-machine:before { content: '\ea05'; } /* '' */
.icon-ic_queue_play_next_48px:before { content: '\ea06'; } /* '' */
.icon-contacts-44:before { content: '\ea07'; } /* '' */
.icon-ic_playlist_add_check_24px:before { content: '\ea08'; } /* '' */
.icon-ic_insert_chart_24px:before { content: '\ea09'; } /* '' */
.icon-ic_insert_invitation_24px:before { content: '\ea0a'; } /* '' */
.icon-ic_delete_24px:before { content: '\ea0b'; } /* '' */
.icon-ic_content_copy_24px:before { content: '\ea0c'; } /* '' */
.icon-ic_attach_file_24px:before { content: '\ea0d'; } /* '' */
.icon-logout:before { content: '\ea0e'; } /* '' */
.icon-menu-4:before { content: '\ea0f'; } /* '' */
.icon-right-arrow:before { content: '\ea10'; } /* '' */
.icon-ic_add_circle_24px:before { content: '\ea11'; } /* '' */
.icon-ic_history_24px:before { content: '\ea12'; } /* '' */
.icon-folder-open:before { content: '\f068'; } /* '' */
.icon-folder-empty:before { content: '\f069'; } /* '' */
.icon-angle-left:before { content: '\f104'; } /* '' */
.icon-angle-right:before { content: '\f105'; } /* '' */
.icon-angle-up:before { content: '\f106'; } /* '' */
.icon-angle-down:before { content: '\f107'; } /* '' */
.icon-file-pdf:before { content: '\f1c1'; } /* '' */
.icon-file-word:before { content: '\f1c2'; } /* '' */
.icon-file-excel:before { content: '\f1c3'; } /* '' */
.icon-file-powerpoint:before { content: '\f1c4'; } /* '' */
.icon-file-image:before { content: '\f1c5'; } /* '' */
.icon-television:before { content: '\f26c'; } /* '' */
.icon-vimeo-circled:before { content: '\f307'; } /* '' */
.icon-twitter:before { content: '\f309'; } /* '' */
.icon-twitter-circled:before { content: '\f30a'; } /* '' */
.icon-facebook:before { content: '\f30c'; } /* '' */
.icon-facebook-circled:before { content: '\f30d'; } /* '' */
.icon-facebook-squared:before { content: '\f30e'; } /* '' */
.icon-gplus:before { content: '\f30f'; } /* '' */
.icon-gplus-circled:before { content: '\f310'; } /* '' */
.icon-pinterest:before { content: '\f312'; } /* '' */
.icon-pinterest-circled:before { content: '\f313'; } /* '' */
.icon-tumblr:before { content: '\f315'; } /* '' */
.icon-tumblr-circled:before { content: '\f316'; } /* '' */
.icon-linkedin:before { content: '\f318'; } /* '' */
.icon-linkedin-circled:before { content: '\f319'; } /* '' */
.icon-dribbble:before { content: '\f31b'; } /* '' */
.icon-dribbble-circled:before { content: '\f31c'; } /* '' */
.icon-stumbleupon:before { content: '\f31e'; } /* '' */
.icon-stumbleupon-circled:before { content: '\f31f'; } /* '' */
.icon-lastfm:before { content: '\f321'; } /* '' */
.icon-lastfm-circled:before { content: '\f322'; } /* '' */
.icon-rdio:before { content: '\f324'; } /* '' */
.icon-rdio-circled:before { content: '\f325'; } /* '' */
.icon-spotify:before { content: '\f327'; } /* '' */
.icon-spotify-circled:before { content: '\f328'; } /* '' */
.icon-qq:before { content: '\f32a'; } /* '' */
.icon-instagram:before { content: '\f32d'; } /* '' */
.icon-dropbox:before { content: '\f330'; } /* '' */
.icon-evernote:before { content: '\f333'; } /* '' */
.icon-flattr:before { content: '\f336'; } /* '' */
.icon-skype:before { content: '\f339'; } /* '' */
.icon-skype-circled:before { content: '\f33a'; } /* '' */
.icon-renren:before { content: '\f33c'; } /* '' */
.icon-sina-weibo:before { content: '\f33f'; } /* '' */
.icon-paypal:before { content: '\f342'; } /* '' */
.icon-picasa:before { content: '\f345'; } /* '' */
.icon-soundcloud:before { content: '\f348'; } /* '' */
.icon-mixi:before { content: '\f34b'; } /* '' */
.icon-behance:before { content: '\f34e'; } /* '' */
.icon-google-circles:before { content: '\f351'; } /* '' */
.icon-vkontakte:before { content: '\f354'; } /* '' */
.icon-smashing:before { content: '\f357'; } /* '' */
.icon-folder-open-empty:before { content: '\f4c2'; } /* '' */
.icon-db-shape:before { content: '\f600'; } /* '' */
.icon-sweden:before { content: '\f601'; } /* '' */
.icon-logo-db:before { content: '\f603'; } /* '' */