.swal2-container {
  .swal2-actions {
    button {
      padding: toRem(10) toRem(16);
      width: toRem(120);
      font-size: toRem(14);
      font-weight: 500;
      line-height: toRem(20);
      border-radius: toRem(8);
    }
    .swal2-confirm {
      background-color: $PrimaryColor;
      order: 2;
    }
    .swal2-cancel {
      background-color: white !important;
      color: $PrimaryText;
      border: 1px solid $BorderColor;
      order: 1;
    }
    button:focus {
      box-shadow: none;
    }
  }
  .swal2-content {
    line-height: 20px;
    font-size: 14px;
    color: $PrimaryText;
  }
  .swal2-title {
    color: $PrimaryText;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
  }
  .swal2-image {
    width: toRem(60);
    height: toRem(60);
  }
  .swal2-icon.swal2-warning {
    color: $PrimaryColor !important;
    background-color: white;
    border: toRem(5) solid $PrimaryColor;
    width: toRem(60);
    height: toRem(60);
  }
  .swal2-icon-success {
    .swal2-success-ring {
      border: toRem(3) solid $SuccessColor !important;
    }
    .swal2-success-line-tip {
      background-color: $SuccessColor !important;
      height: toRem(6) !important;
    }
    .swal2-success-line-long {
      background-color: $SuccessColor !important;
      height: toRem(6) !important;
    }
    .swal2-actions {
      .swal2-confirm {
        background-color: $SuccessColor;
        order: 3;
      }
      .swal2-cancel {
        background-color: white;
        color: $PrimaryText;
        border: 1px solid $BorderColor;
        order: 1;
      }
      button:focus {
        box-shadow: none;
      }
    }
  }
  .errorCustom {
    .swal2-actions {
      .swal2-confirm {
        background-color: $DangerColor;
      }
    }
  }
  .successCustom {
    .swal2-actions {
      .swal2-confirm {
        background-color: $SuccessColor;
      }
    }
  }
}
