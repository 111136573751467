//Define font-size for html root. We can use rem unit instead px to easy for responsive.
@use "sass:math";
$baseSize: 14;
@function calculateBaseFont() {
    $vw-context: (1920*.01) * 1px;
    @return calc(14px/$vw-context) * 1vw;
}
html {
  font-size: calculateBaseFont();
  @media screen and (max-width: 1200px) {
    font-size: 8px;
    $baseSize: 14;
  }
  @media screen and (min-width: 3501px) {
    font-size: 25px;
    $baseSize: 14;
  }
}

// @function toRem($size) {
//   // @return $size / $baseSize * 1rem;
//   $vw-context: (1920*.01) * 1px;
//   $target: $size * 1px;
//   @return ($target/$vw-context) * 1vw;
// }

@function toRem($size) {
  @return calc($size / $baseSize) * 1rem;
}