@import 'styles/scss/variable.scss';

.Select-Wraper {
  position: relative;

  .placeholder {
    position: absolute;
    top: toRem(10);
    left: toRem(12);
    color: $GrayColor;
    z-index: 5;
  }

  :global(.MuiOutlinedInput-root) {
    font-size: toRem(14);
    border-radius: toRem(6);
    z-index: 3;

    :global(.MuiOutlinedInput-input) {
      border-radius: toRem(6);
      padding: toRem(8) toRem(12);
      color: $PrimaryText;
      background-color: transparent;

      fieldset {
        border: 1px solid $BorderColor;
        border-radius: toRem(6);

        legend {
          width: 0;
        }
      }
    }

    textarea {
      padding-left: 0 !important;
      padding-top: 0 !important;
    }
  }

  .placeholderInput {
    :global(.MuiSelect-select) {
      color: transparent !important;
    }
  }

  :global(.Mui-focused) {
    fieldset {
      border: 1px solid $PrimaryText !important;
    }
  }
}