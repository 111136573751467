@import 'styles/scss/variable.scss';
.Dialog-Wrapper {
  .onCloseIcon771753 {
    position: absolute;
    right: toRem(24);
    top: toRem(16);
    cursor: pointer;
    svg {
      width: toRem(20);
      height: toRem(20);
    }
  }
  :global(.MuiPaper-root) {
    min-width: fit-content;
    border-radius: toRem(16);
    :global(.MuiDialogTitle-root) {
      border-bottom: 1px solid #edebeb;
      font-size: toRem(16);
      padding: toRem(18) toRem(24);
    }
    :global(.MuiDialogContent-root) {
      padding: toRem(20) toRem(24);
      max-width: toRem(520);
    }
    :global(.MuiDialogActions-root){
      display: flex;
      gap: toRem(8);
      padding: toRem(18) toRem(16);
      :global(.MuiButton-root) {
        min-width: toRem(120);
      }
    }
  }
}