@import 'styles/scss/variable.scss';

.ConditionForm {
  padding: toRem(24) toRem(24);
  box-sizing: border-box;

  .description {
    font-size: toRem(16);
    line-height: toRem(20);
    opacity: 0.9;
  }

  .conditionCard {
    width: 100%;
    box-sizing: border-box;
    // margin-top: toRem(32);

    .removeField {
      cursor: pointer;
      width: toRem(20);
      height: toRem(20);
      position: relative;

      svg {
        width: toRem(20);
        height: toRem(20);
      }
    }

    :global(.MuiOutlinedInput-input) {
      background-color: white;
    }

    :global(.CollapseCard) {
      margin-bottom: toRem(32);
      :global(.header) {
        background-color: $GrayColor4;
      }
      :global(.childrenContent) {
        background-color: $GrayColor4;
        padding: toRem(16);
      }
    }
  }

  .fieldRow {
    display: flex;
    gap: toRem(35);
    :global(.MuiOutlinedInput-root) {
      background-color: white;
    }
  }

  .addGroupConditionButton {
    border: 1px dashed $BorderColor;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $PrimaryColor;
    font-size: toRem(14);
    font-weight: 500;
    line-height: toRem(20);
    padding: toRem(16) 0;
    border-radius: toRem(16);
    cursor: pointer;
    gap: toRem(6);
  }
  .RadioGroupWrapper {
    display: contents;
  }

  .addButtonText {
    display: flex;
    align-items: center;
    color: $PrimaryColor;
    font-size: toRem(14);
    font-weight: 500;
    line-height: toRem(20);
    cursor: pointer;
    gap: toRem(6);
    width: fit-content;
  }
}