.drop-zone-wrap {
    border: 1px solid #263238;
    border-style: dashed;
    padding: 0.7em;
    cursor: pointer;
    // color: $font-color;
    font-size: 1em;
    &__instruction {
        padding: 1.5em 0.5em;
        // height: 100%;
        &:focus {
            outline: none;
        }
    }
    &--error {
        border-color: #ce0832 !important;
    }
}
.btn-delete-image-preview {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 0;
    z-index: 100;
    border-radius: 99%;
    svg {
        fill: #fff;
    }
}
