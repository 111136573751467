@import 'styles/scss/variable.scss';

.DetaiTabs {
  background-color: $ContentColor;
  border-radius: toRem(16);
  box-shadow: 0px 5px 10px 10px rgba(22, 22, 22, 0.1);
  margin-top: toRem(-15);
  position: relative;
  :global(.TabsWrapper) {
    padding: 0 toRem(26);
    padding-top: toRem(12);
    height: toRem(60);
    box-sizing: border-box;

    :global(.MuiTabs-root) {
      min-height: 100% !important;
      height: 100% !important;

      :global(.MuiTabs-scroller) {
        min-height: 100% !important;
        height: 100% !important;
      }
    }
  }

  :global(.bodyMainContentWrapper) {
    padding: 0;
    :global(.listTable) {
      box-shadow: none;
      margin-top: 1rem;
    }
  }

  :global(.ListTable_header) {
    position: absolute;
    top: 0;
    right: 0;
  }

  :global(.table) {
    margin: 0px;
    background-color: #fff;
    box-shadow: none;
    border-radius: 0;
    border-bottom-left-radius: toRem(16);
    border-bottom-right-radius: toRem(16);

    thead {
      // background-color: #fffbfb;
      background-color: #F4F4F4;

      th {
        color: $PrimaryText;
      }
    }

    th {
      &:first-of-type {
        border-top-left-radius: 0;
      }

      &:last-of-type {
        border-top-right-radius: 0;
      }
    }
  }
}