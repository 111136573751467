@import "./variable.scss";
#subscriptions-page {
    margin: 0px;
    font-family: 'Roboto';
    .page-detail-body {
        padding-top: 0;
    }
    .machine-info {
        background-color: white;
        box-shadow: 0px toRem(5) toRem(10) toRem(10) rgba(22, 22, 22, 0.1);
        border-radius: toRem(16);
        box-sizing: border-box;
        padding: toRem(16) toRem(16);
        .machine-box-main {
            background-color: $PrimaryText;
            border-radius: toRem(12);
            padding: toRem(24) toRem(16);
            font-size: $BodyFontSize;
            color: white;
            height: toRem(152);
            box-sizing: border-box;
            width: toRem(400);
            span {
                padding: 17px 11px;
                background-color: #333537;
                border-radius: 50%;
                margin-right: 20px;
                position: relative;
                top: -5px;
                i {
                    font-size: 22px;
                }
            }
            p {
                margin-block-start: toRem(0);
                margin-block-end: toRem(0);
            }
            .machine-id {
                font-size: $HeadlineFontSize;
                font-weight: 500;
                margin-block-start: toRem(16);
                margin-block-end: toRem(16);
            }
            .machine-type {
                font-size: toRem(18);
                line-height: toRem(24);
                // margin-inline-end: 0;
            }
            a {
                color: #f2f2f2;
                &:hover {
                    color: #cecece;
                }
            }
        }
        .machine-box-info {
            // padding-left: toRem(22);
            height: toRem(152);
            width: toRem(1165);
            box-sizing: border-box;
            padding-left: toRem(24);
            .machine-box-info-line {
                margin-top: toRem(-3);
                margin-bottom: toRem(30);
                height: 1px;
                background-color: $BorderColor;
            }
            .machine_note_field {
                height: toRem(59);
                box-sizing: border-box;
                .MuiOutlinedInput-root {
                    height: toRem(59);
                    box-sizing: border-box;
                    padding: toRem(8) toRem(12);
                }
            }
            .machine-box-info-top {
                span {
                    color: $SecondaryText;
                    margin-bottom: toRem(8);
                    display: block;
                    font-family: 'Roboto';
                    margin-bottom: toRem(-12);
                }
                p {
                    font-size: $HeaderFontSize;
                    color: $PrimaryText;
                    font-weight: 500;
                    font-family: 'Roboto';
                }
            }
            .machine-box-info-bottom {
                color: $SecondaryText;
                font-family: 'Roboto';
                b {
                    color: $PrimaryText;
                }
            }
        }
    }
    .result-exercise {
        display: block;
        text-align: center;
        margin-top: 20px;
        .result-item {
            float: left;
            width: calc(20% - 5px);
            margin: 0px 5px 5px 0px;
            border-radius: 4px;
            background-color: #fff;
            padding: 16px 0px 5px 0px;
            p {
                &:first-child {
                    color: $SecondaryText;
                    font-size: 12px;
                    font-weight: 500;
                    text-transform: uppercase;
                }
                &:last-child {
                    color: #a5333e;
                    font-size: 40px;
                    font-weight: 500;
                }
            }
        }
        &:after {
            content: '';
            display: block;
            clear: both;
        }
    }
}
