.actions {
  display: flex;
  align-content: center;
  &-icon {
    cursor: pointer;
    margin-top: 2px;
    &-accepted {
      margin-right: 20px;
    }
    &-loading {
      cursor: not-allowed !important;
    }
  }
}

.info-icon {
  color: #666666;
  font-size: 21px !important;
}

.tootip-title {
  font-size: 16px;
  color: #161616;
  margin-bottom: 0;
}
.tootip-content {
  font-size: 14px;
  color: #161616;
  font-weight: 400;
  display: -webkit-box;
  -webkit-line-clamp: 30;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
