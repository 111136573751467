@import 'styles/scss/variable.scss';

.EditGameSettingModal {
  .row {}
  UL, LI {
    padding:0;
    margin:0;
  }
  li {
    list-style:none;
    line-height:50px;
    background-color:#eee;
    border-bottom:1px solid #ccc;
    padding-left:1em;
  }
}