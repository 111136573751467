@import "./variable.scss";
#subscriptionDetailPage {
    margin: 0px;
    font-family: 'Roboto';
    .machine-info {
        background-color: $ContentColor;
        border-radius: toRem(16);
        padding: toRem(24) toRem(24);
        box-shadow: 0px 5px 10px 10px rgba(22, 22, 22, 0.1);
        animation-name: animateLoadPage;
        animation-duration: 2s;
        .machine-box-main {
            background-color: $PrimaryText;
            border-radius: toRem(12);
            color: #fff;
            padding: toRem(18) toRem(16);
            .SubscriptionStatus {
                margin-bottom: toRem(16);
            }
            .avironSubscriptionTitle {
                font-size: toRem(24);
                font-weight: 500;
                color: #fff;
            }
            font-size: toRem(14);
            .stripeInfoWrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: toRem(18);
                margin-top: toRem(16);
            }
        }
        .machine-box-info {
            .machine-box-info-body {
                .MuiGrid-item {
                    border-bottom: 1px solid $GrayColor1;
                    padding-top: toRem(16);
                    div {
                        font-size: toRem(14);
                        line-height: 20px;
                        color: $GrayColor;
                    }
                    p {
                        font-size: toRem(18);
                        font-weight: 500;
                        line-height: toRem(10);
                    }
                }
                .itemNote {
                    border-bottom: 0;
                    margin-top: toRem(16);
                    textarea {
                        height: toRem(220) !important;
                    }
                }
                .userItem {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    p {
                        color: $PrimaryText;
                    }
                }
                .machineIdItem {
                    a {
                        color: $PrimaryColor;
                    }
                }
            }
        }
    }
    .result-exercise {
        display: block;
        text-align: center;
        margin-top: 20px;
        .result-item {
            float: left;
            width: calc(20% - 5px);
            margin: 0px 5px 5px 0px;
            border-radius: 4px;
            background-color: #fff;
            padding: 16px 0px 5px 0px;
            p {
                &:first-child {
                    color: $SecondaryText;
                    font-size: 12px;
                    font-weight: 500;
                    text-transform: uppercase;
                }
                &:last-child {
                    color: #a5333e;
                    font-size: 40px;
                    font-weight: 500;
                }
            }
        }
        &:after {
            content: '';
            display: block;
            clear: both;
        }
    }
}
