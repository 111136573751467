@import 'styles/scss/variable.scss';
.PaginationControlledWrapper {
  color: $SecondaryText;
  width: 100%;
  text-align: left;
  :global(.numbersPagingWrapper) {
    display: flex !important;
    text-align: left !important;
  }
  label {
      display: block;
      text-align: right;
      select {
          border-radius: toRem(4);
          border: solid toRem(1) #979797;
      }
  }
  ul {
      text-align: left;
      li {
          display: inline-block;
          padding: 0px toRem(1);
          :global(.Mui-selected) {
            border: none !important;
            color: white;
            background-color: $PrimaryColor;
          }
          :global(.MuiPaginationItem-ellipsis) {
            font-size: toRem(35);
            opacity: 0.6;
            border: none;
          }
          :global(.MuiPaginationItem-firstLast) {
            background-image: url('./lastNumberIcon.svg');
            background-repeat: no-repeat;
            background-position: 1px 0px;
            background-size: cover;
            svg {
              display: none;
            }
          }
      
          &:first-of-type {
            :global(.MuiPaginationItem-firstLast) {
              transform: rotate(180deg);
            }
          }
      }
  }
  :global(.MuiPaginationItem-root) {
    border: toRem(1) solid #808080;
    color: $SecondaryText;
    padding: 0;
    padding-top: toRem(2);
    min-width: toRem(32);
    min-height: toRem(32);
    max-width: toRem(32);
    max-height: toRem(32);
    width: toRem(32);
    height: toRem(32);
    border-radius: toRem(100);
    font-size: toRem(12);
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
}