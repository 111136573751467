@import 'styles/scss/variable.scss';

.TabsWrapper {
  :global(.MuiTabs-root) {
    align-items: center;
    :global(.MuiTabs-indicator) {
      height: toRem(4);
      border-radius: toRem(10);
    }
    :global(.MuiTabs-scroller) {
      height: toRem(39);
    }
    :global(.MuiTabs-flexContainer) {
      gap: toRem(8);
      :global(.MuiButtonBase-root) {
        border-radius: toRem(4);
        font-size: toRem(16);
        color: $GrayColor;
        line-height: 19px;
        padding: toRem(8) toRem(8);
        text-transform: capitalize;
        font-weight: normal;
        min-height: toRem(20);
        align-items: flex-start;
        -webkit-align-items: flex-start;
        -webkit-box-align: flex-start;
        justify-content: flex-start;
        -webkit-justify-content: flex-start;
        -webkit-box-align:start;
        min-width: fit-content;
      }
      :global(.Mui-selected) {
        color: $PrimaryText;
        font-weight: 500;
      }
    }
  }
}