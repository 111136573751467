@import "./variable.scss";
body {
    background-color: $BodyColor;
    font-family: Roboto;
    overflow: auto;
    font-size: toRem(14);
    color: $PrimaryText;
    table {
        td {
            animation-name: animateLoadPage;
            animation-duration: 1s;
        }
    }
    div, p {
        font-size: toRem(14);
        @media screen and (min-width: $DesktopMediumMinSize) and (max-width: $DesktopMediumMaxSize) {
            font-size: 13px;
        }
    }
    i {
        outline: none;
        font-size: toRem(14);
    }
    //  @media screen and (min-width: 3000px) {
    //     font-size: 20px;
    //     div, p, i {
    //         font-size: 20px;
    //     }
    //  }
}
.pointer {
    cursor: pointer;
}
a {
    color: #ce0832;
    cursor: pointer;
    text-decoration: none;
    &:hover {
        color: #333333;
        text-decoration: none;
    }
}
input::-ms-clear {
    width: 0;
    height: 0;
}
input[type="number"] {
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}
.underline {
    text-decoration: underline;
}
.filter-title {
    // line-height: 36px;
}
.text-right {
    text-align: right;
}
.options-form {
    padding-left: 33px;
    position: relative;
    margin-bottom: 16px !important;
    &:before {
        content: "";
        border-left: 1px solid #979797;
        height: calc(100% - 72px);
        position: absolute;
        top: 36px;
        left: 6px;
    }
    &:after {
        content: "or";
        position: absolute;
        top: calc(50% - 12px);
        left: 2px;
        color: #212121;
        background-color: #fff;
    }
    input {
        height: 56px;
        box-sizing: border-box;
    }
}
.page-detail-body {
    padding: toRem(16) 0;
}
/*--- START Loading ---*/
.loading {
    display: none;
    left: 0px;
    top: 0px;
    text-align: center;
    width: 100%;
    position: fixed;
    height: 100vh;
    padding-top: 40vh;
    z-index: 10000;

    .loading-container {
        background-color: white;
        width: 85px;
        padding: 10px;
        border-radius: 5px;
        position: fixed;
        top: 3px;
        left: 50vw;

        .lds-dual-ring {
            display: inline-block;
            width: 50px;
            height: 50px;
        }

        .lds-dual-ring:after {
            content: " ";
            display: block;
            width: 46px;
            height: 46px;
            margin: 1px;
            border-radius: 50%;
            border: 5px solid $PrimaryColor;
            border-color: $PrimaryColor transparent $PrimaryColor transparent;
            animation: lds-dual-ring 1.2s linear infinite;
        }

        @keyframes lds-dual-ring {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }
    }
}
.loading-mask {
    display: none;
    left: 0px;
    top: 0px;
    text-align: center;
    width: 100%;
    position: fixed;
    height: 100vh;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.3);
}
/*--- END Loading ---*/

/*--- START Web container ---*/
.web-container {
    display: flex;
    align-items: stretch;
}
/*--- END Web container ---*/

$HeaderBackgroundColor: #ffffff;

/*--- START Sidebar ---*/
.toolbar {
    background-color: $HeaderBackgroundColor !important;
}
/*--- END Sidebar ---*/
/*--- START Live ---*/
$MotorizedElementWidth: 300px;
#live {
    padding: 24px 30px;
    width: 100%;
    position: relative;

    .screen-division {
        padding-right: $MotorizedElementWidth;
    }

    .motorized-element {
        position: absolute;
        top: 24px;
        right: 0px;
        width: $MotorizedElementWidth;
        min-height: 550px;
        transition: all 0.5s ease;
        background-color: white;
        overflow-x: hidden;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
        height: calc(100vh - 88px);

        .motorized {
            padding: 20px 16px 0px 20px;
            background-color: #fff;

            .tb-autofocus {
                width: 100%;
            }

            &:not(:last-child) {
                border-bottom: 1px solid #d8d8d8;
            }

            .tb-focus {
                width: 182px;
                font-size: 12px;
            }

            .control-label-motorized {
                font-size: 16px;
                color: $PrimaryText !important;
                margin: 4px 0px;
            }

            h4 {
                font-size: 16px;
                color: rgba(0, 0, 0, 0.87);
                letter-spacing: 0;
                margin-bottom: 16px;
            }

            .button {
                line-height: 20px;
                text-align: right;
                padding: 8px 5px;
            }

            &:last-child {
                .control-label-motorized {
                    margin-bottom: 5px !important;
                }
            }

            .checked-enable {
                height: 40px;

                input {
                    height: 40px;
                }

                > span:first-child {
                    height: 40px;
                }
            }
        }
    }
}
/*--- END Live ---*/
/*--- START Web container content ---*/
.web-container-content {
    width: 100%;
}
/*--- END Web container content ---*/
/*--- START LIVE VIEW ---*/
$TitleColor: $PrimaryText;
#container {
    position: relative;
    // top: toRem(15);
    .position-relative {
        position: relative;
    }
    .paging-show {
        div,
        span {
            font-size: $BodyFontSize !important;
        }
    }
    #chart {
        background-color: #fff;
        margin-top: 30px;
    }
    //Will remove after done new ui
    .rightMainWrapper {
        padding: 0 toRem(30);
        padding-bottom: toRem(30);
    }
}
.action-list {
    border-radius: 4px;
    box-shadow: 0 6px 12px 0 rgba(186, 179, 179, 0.5) !important;
    background-color: #fff;
    position: absolute;
    z-index: 1;
    right: 0px;
    width: 106px;
    padding: 4px;
    .actions-table-delete {
        display: inline-block;
        width: 100%;
        padding: 4px 8px;
    }
}
/*--- END LIVE VIEW ---*/

/*--- START Config title ---*/
.config-title {
    .title-container {
        min-height: 47px;

        &:after {
            content: "";
            display: block;
            clear: both;
        }

        .title {
            float: left;
            @extend %HeadlineFont;
            @extend %RobotoRegular;
            color: $PrimaryColor;
        }

        .buttons-container {
            float: right;
            button {
                margin-left: 5px;
            }
        }
    }
}
.config-content {
    margin-top: 10px;
}
/*--- END Config title ---*/

/*--- START Dialog Setup Page ---*/

ul,
li {
    font-size: $BodyFontSize !important;
}
svg {
    font-size: $HeaderFontSize !important;
}
/*--- END Dialog Setup Page ---*/

/*--- START WEB TABs VERTICAL ---*/
.web-tabv {
    width: 100%;
    border: 1px solid $BorderColor;
    background-color: $BodyColor;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    display: inline-flex;
    min-width: 600px;

    .web-tabv-href {
        float: left;
        width: 20%;
        min-height: $MinHeight;
        padding-top: 10px;

        .web-tabv-a {
            width: 100%;
            height: 48px;
            color: $SecondaryText;
            display: flex;
            align-items: center;
            padding-left: 15px;
            cursor: pointer;

            i {
                margin-right: 15px;
                cursor: pointer;
            }

            span {
                cursor: pointer;
                @extend %BodyMenuFont;
            }
        }

        .web-tabv-a:hover {
            background-color: #fff;
        }

        .web-tabv-a-selected {
            background-color: #fff;
        }
    }

    .web-tabv-content {
        float: left;
        width: 80%;
        background-color: #fff;
        min-height: 100px;
        min-height: calc(100vh - 220px);
        padding: 25px;

        .content-setup {
            width: 100%;
        }
    }
}
/*--- END WEB TABs VERTICAL ---*/

/*--- START PANEL COLLAPSE ---*/
$WebPanelBackgroundColor: #f7f7f7;
.web-panel-collapse {
    background-color: $WebPanelBackgroundColor !important;
    margin-bottom: 25px;

    .web-collapse-icon {
        font-size: $CaptionFontSize;
        color: $SecondaryText;
    }

    .web-panel-title {
        @extend %SubheaderFont;
        @extend %RobotoMedium;
        color: $PrimaryText;
    }

    .web-panel-content {
        display: block;
    }

    .form-control {
        background-color: $WebPanelBackgroundColor;
        margin-bottom: 5px;

        .form-control-note {
            text-align: right;
        }
    }
}
/*--- END PANEL COLLAPSE ---*/

/*--- START FORM CONTROL ---*/
.form-container {
    padding: 24px;
    background-color: $ContentColor;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
}
.form-control {
    width: 100%;

    .form-control-error {
        color: $DangerColor;
    }
}
/*--- END FORM CONTROL ---*/

/*--- START FORM CONTROL ---*/
.textfield-icon-container {
    position: relative;

    input[type="text"] {
        padding-right: 48px;
    }
    .textfield-icon {
        position: absolute;
        top: 12px;
        right: 0px;
    }
}
/*--- START FORM CONTROL ---*/

@media all and (min-width: 1280px) {
    body {
        overflow-x: hidden;
    }
}

.warning-message {
    color: #F3A72E;
    font-size: toRem(12);
}

.bodyMainContentWrapper {
    padding: 0 toRem(24) toRem(30) toRem(24);
    display: flex;
    justify-content: center;
    margin-top: toRem(-16);
}
.pd-content {
    padding: 0 toRem(30);
    padding-bottom: toRem(30);
}