.table {
  margin: 0px;
  background-color: #fff;
  box-shadow: 0px 5px 10px 10px rgba(22, 22, 22, 0.1);
  padding-bottom: toRem(16);
  border-radius: toRem(24);
  margin-top: toRem(24);

  .MuiTableCell-root {
    color: $PrimaryText;
  }

  thead {
    // background-color: #fffbfb;
    background-color: #F4F4F4;

    th {
      color: $PrimaryText;
    }
  }

  th {
    &:first-of-type {
      border-top-left-radius: toRem(24);
    }

    &:last-of-type {
      border-top-right-radius: toRem(24);
    }
  }
}