@import 'styles/scss/variable.scss';
.MainGameSetting {
  .main {
    display: flex;
    gap: toRem(24);
    justify-content: center;
    margin-top: toRem(24);
    margin-bottom: toRem(24);
    .settingBlock {
      margin-bottom: toRem(20);
      :global(.form-aviron) {
        margin-bottom: toRem(30) !important;
      }
      .header {
        font-family: Roboto;
        font-size: toRem(20);
        font-style: normal;
        font-weight: 500;
        line-height: toRem(24); /* 120% */
        margin-bottom: toRem(24);
        color: #666;
      }
    }
  }
}