@import "styles/scss/variable.scss";
.StatusLabelWrapper {
  display: flex;
  align-items: center;
  justify-self: center;
  width: fit-content;
  gap: toRem(6);
  border-radius: toRem(4);
  padding: toRem(0) toRem(8);
  height: toRem(20);
  word-break: keep-all;
  min-width: toRem(52);
  .circle {
    border-radius: toRem(20);
    width: toRem(6);
    height: toRem(6);
    min-width: toRem(6);
    min-height: toRem(6);
    max-width: toRem(6);
    max-height: toRem(6);
  }
  .statusText {
    word-break: keep-all;
    font-weight: 500;
    font-size: toRem(12) !important;
    line-height: toRem(16) !important;
    @media screen and (min-width: $DesktopMediumMinSize) and (max-width: $DesktopMediumMaxSize) {
      font-size: 12px !important;
      line-height: 16px !important;
    }
  }
}
