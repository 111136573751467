@import 'styles/scss/variable.scss';
.MenuItemCollapse {
    :global(.MuiAccordion-root) {
        box-shadow: none !important;
        background-color: transparent;
        :global(.MuiAccordionSummary-root) {
            background-color: #222428;
            padding: 0 toRem(10);
            margin: 0;
            height: toRem(48) !important;
            :global(.MuiListItemText-root) {
                color: #ccc;
                font-weight: 500;
                @media screen and (min-width: $DesktopMediumMinSize) and (max-width: $DesktopMediumMaxSize) {
                    font-size: 14px;
                }
            }
            :global(.MuiAccordionSummary-expandIconWrapper) {
                svg, path {
                    fill: #ccc;
                }
            }
            :global(.MuiAccordionSummary-content){
                margin: 0;
                :global(.MuiListItemIcon-root) {
                    align-items: center;
                }
            }
        }
        :global(.MuiAccordionSummary-root.Mui-expanded) {
            margin: 0;
            svg, path {
                fill: white;
            }
            :global(.MuiListItemText-root) {
                color: white;
                font-weight: 500;
            }
            :global(.MuiAccordionSummary-expandIconWrapper) {
                svg, path {
                    fill: white;
                }
            }
        }
        :global(.MuiCollapse-root) {
            background-color: #222428;
            :global(.MuiAccordionDetails-root) {
                color: white;
            }
        }
        :global(.MuiAccordionDetails-root) {
            padding: 0;
            :global(.MuiListItemText-root) {
                padding-left: toRem(39);
            }
        }
    }
}