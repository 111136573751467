/* Css for fields form Mui: input, select, text area,... */

.MuiFormControl-root {
    .MuiInputLabel-root {
      font-size: toRem(14);
      background-color: transparent;
      color: $PrimaryText;
      transform-origin: unset;
      transform: translateY(toRem(-20));
      font-size: toRem(12);
      font-weight: 700;
      &.Mui-focused {
        color: $PrimaryText;
        transform-origin: unset;
      }
      &.Mui-error {
        color: $PrimaryText;
      }
    }
    label[data-shrink] {
      ~ .MuiOutlinedInput-root {
        input::placeholder {
          opacity: 0.5 !important;
        }
        input::-webkit-input-placeholder {
          opacity: 0.5 !important;
        }
        textarea::placeholder {
          opacity: 0.5 !important;
        }
        textarea::-webkit-input-placeholder {
          opacity: 0.5 !important;
        }
      }
    }
    .MuiOutlinedInput-root {
      font-size: toRem(14);
      .MuiOutlinedInput-input {
        padding: toRem(8) toRem(12);
        color: $PrimaryText;
        background-color: transparent;
      }
      fieldset {
        border: 1px solid $BorderColor;
        border-radius: toRem(6);
        legend {
          width: 0;
        }
      }
      &.Mui-focused {
        fieldset {
          border: 1px solid $PrimaryText;
        }
      }
      textarea {
        padding-left: 0 !important;
        padding-top: 0 !important;
      }
    }
    .MuiFormHelperText-root.Mui-error {
      margin-left: 0;
    }
    .MuiSvgIcon-root.MuiSelect-icon {
      color: #666;
    }
    .MuiInputLabel-root.Mui-disabled {
      color: $PrimaryText;
    }
  }

  .form-aviron {
    margin-bottom: toRem(16) !important;
    &.select {
      margin: toRem(16) 0px toRem(24) 0px !important;
    }
    span {
      top: 25%;
  
      i {
        font-size: toRem(24);
      }
    }
  }