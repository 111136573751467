@import 'styles/scss/variable.scss';

.ParentProductCategorySelect {
  // position: relative;
  width: 100%;
  box-sizing: border-box;
  z-index: 10;
  .inputSelect {
    box-sizing: border-box;
    border-radius: 6px;
    border: 1px solid #CCC;
    background: white;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: toRem(36);
    position: relative;
    .inputGroup {
       width: 100%;
       height: inherit;
       display: flex;
       align-items: center;
        .inputResult {
          width: 100%;
          height: inherit;
          position: absolute;
          background-color: transparent;
          display: flex;
          align-items: center;
          padding-left: toRem(12);
          // background-color: yellow;
        }
        input {
          position: absolute;
          outline: 0;
          border: 0;
          width: 100%;
          padding-left: toRem(12);
          background-color: transparent;
        }
        input::placeholder {
          font-size: toRem(14);
          font-family: 'Roboto';
        }
      }
    }
    .iconGroup {
      display: flex;
      gap: toRem(3);
      position: absolute;
      right: toRem(8);
      .clearIcon, .expandIcon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: toRem(20);
        height: toRem(20);
        border-radius: toRem(100);
        svg {
          fill: #666;
          cursor: pointer;
        }
      }
    }
  .menu {
    position: absolute;
    background-color: white;
    width: inherit;
    min-width: toRem(250);
    box-shadow: 0px 1px 10px 0px rgba(29, 45, 73, 0.10);
    border-radius: toRem(6);
    margin-top: toRem(6);
    max-height: toRem(300);
    overflow: auto;
    padding: toRem(8) toRem(0);
    .item {
      padding: toRem(8) toRem(12);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      cursor: pointer;
      &:hover {
        background-color: rgba(245, 73, 15, 0.12);
      }
    }
    .itemActive {
      background-color: rgba(245, 73, 15, 0.12);
    }
  }
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: $zIndexLvMax;
    top:0
  }
}