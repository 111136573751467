.headerToolbarGrid {
  animation-name: animateLoadPage;
  animation-duration: 2s;
  background-color: white;
  padding: 0px toRem(24);
  justify-content: space-between;
  margin-top: 0 !important;
  width: 100% !important;
  margin-left: 0 !important;
  .MuiGrid-item {
    padding: toRem(16) 0 !important;
  }

  .buttonGroup {
    display: flex;
    align-items: center;
    justify-content: end;
  }

  .aviron-title {
    color: $PrimaryText;
    font-size: toRem(24);
    font-weight: 500;

    i {
      position: relative;
      top: toRem(-3);
      left: toRem(-3);
    }
  }

  .aviron-sub-title {
    color: $SecondaryText;
    font-size: 12;
    margin-bottom: toRem(3);
    margin-left: toRem(12);
  }
}