.JsonEditorFormField {
  position: relative;
  :global(#metadata-container) {
    border-radius: 8px;
    border: 1px solid #C3C3C3;
    :global(#metadata-labels){
      background-color: #EBEBEB;
      width: auto !important;
      padding: 5px 10px !important;
      border-end-start-radius: 8px;
      border-top-left-radius: 8px;
    }
  }
  .disableEditor {
    width: 100%;
    height: 100%;
    z-index: 6;
    position: absolute;
    background-color: #ccc;
    opacity: 0.6;
    border-radius: 8px;
  }
}