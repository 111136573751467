@import "styles/scss/variable.scss";

.UserAccount {
  position: relative;
  .usernameWrapper {
    display: flex;
    align-items: center;
    gap: toRem(10);
    cursor: pointer;

    .username {
      font-size: toRem(14);
      color: $GrayColor3;
    }

    .avatar {
      :global(.MuiAvatar-root) {
        width: toRem(39);
        height: toRem(39);
      }
    }
  }

  .menuDropdown {
    z-index: $zIndexLv1;
    border-radius: toRem(5);
    border: 1px solid $BorderColor;
    box-shadow: 0px 1px toRem(10) 0px rgba(29, 45, 73, 0.10);
    width: toRem(160);
    padding: toRem(8) toRem(0);
    background-color: $ContentColor;
    position: absolute;
    right: 0;
    .item {
      font-size: toRem(14);
      font-weight: 500;
      line-height: toRem(20);
      color: $PrimaryText;
      display: flex;
      align-items: center;
      padding: toRem(12) toRem(7);
      gap: toRem(8);
      cursor: pointer;
      &:hover {
        background-color: $ActiveItemColor;
      }
      svg {
        width: toRem(20);
        height: toRem(20);
      }
    }
  }
}