@import './fontSizeByRem.scss';
$zIndexLv1: 1500;
$zIndexLvMax: 9999999;
// colors
$BodyColor: #f5f5f5;
$ContentColor: #ffffff;
$SidebarBackgroundColor: #353535;
$BoxShadowDialog: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
$HeaderBackgroundColor: #161616;
$ActiveItemColor: rgba(245, 73, 15, 0.2);

$GrayColor: #808080;
$GrayColor1: #E6E6E6;
$GrayColor2: #666666;
$GrayColor3: #939393;
$GrayColor4: #F4F4F4;

$TextIconsColor: #ffffff;
$AccentColor: #36d1dc;
$PrimaryText: #161616;
$SecondaryText: #808080;
$DividerColor: #bdbdbd;
$ElementColor: #f2f2f2;

$DarkPrimaryColor: #E03100;
$PrimaryColor: #F5490F;
$LightPrimaryColor: #F76D3F;
$SuccessColor: #00BC3C;
$SuccessDarkColor: #008129;
$SuccessLightColor: #80DE9E;
$WarningColor: #F3A72E;
$WarningDarkColor: #DC931F;
$WarningLightColor: #F8CE8B;
$DangerColor: #E60A32;
$DangerDarkColor: #AF0725;
$DangerLightColor: #F38599;
$InfoColor: #2F6BFF;
$BlueLightColor: #82A6FF;
$BlueDarkColor: #1144C2;
$HoverWhiteColor: #f9f8f8; // when hover an element has background color is white
$BorderHoverColor: #ebebeb;
$BorderColor: #ccc;
$F8F8F8: rgba(189, 189, 189, 0.1);
$NotificationColor: #c7261b;
$BackgroundCollapseBtn: #5c5c5c;
$TableHoverColor: #f5f5f5;

// text font size
$Display4FontSize: 112px;
$Display3FontSize: 56px;
$Display2FontSize: 45px;
$Display1FontSize: 34px;
$HeadlineFontSize: 24px;
$TitleFontSize: 20px;
$HeaderFontSize: toRem(18);
$SubheaderFontSize: 16px;
$BodyMenuFontSize: 14px;
$BodyFontSize: toRem(14);
$CaptionFontSize: 12;
$ButtonFontSize: 14px;
//font-family, default is roboto-regular
%RobotoLight {
    font-family: Roboto;
    font-weight: 300;
}
%RobotoMedium {
    font-family: Roboto;
    font-weight: 600;
}
%RobotoRegular {
    font-family: Roboto;
    font-weight: normal;
}
%RobotoBold {
    font-family: Roboto;
    font-weight: bold;
}

//text font
%Display4Font {
    @extend %RobotoLight;
    font-size: $Display4FontSize;
    letter-spacing: -0.22px;
}
%Display3Font {
    font-size: $Display3FontSize;
    letter-spacing: -0.11px;
}
%Display2Font {
    font-size: $Display2FontSize;
    letter-spacing: -0.09px;
}
%Display1Font {
    font-size: $Display1FontSize;
    letter-spacing: -0.07px;
}
%HeadlineFont {
    font-size: $HeadlineFontSize;
    letter-spacing: -0.05px;
}
%TitleFont {
    @extend %RobotoMedium;
    font-size: $TitleFontSize;
    letter-spacing: -0.04px;
}
%SubheaderFont {
    font-size: $SubheaderFontSize;
    letter-spacing: -0.03px;
}
%BodyMenuFont {
    @extend %RobotoMedium;
    font-size: $BodyMenuFontSize;
    letter-spacing: -0.03px;
}
%BodyFont {
    font-size: $BodyFontSize;
    letter-spacing: -0.03px;
}
%CaptionFont {
    font-size: $CaptionFontSize;
    letter-spacing: -0.02px;
}
%ButtonFont {
    @extend %RobotoMedium;
    font-size: $ButtonFontSize;
    letter-spacing: -0.03px;
}

$color-primary-red: #f5490f;
$color-primary-black: #3a3a3a;
$color-gray:#9f9f9f;
$color-gray-light: #dcdcdc;
$color-gray-lightest: #f6f6f6;
$color-white: #ffffff;

//text color
.primary-color {
    color: $PrimaryColor !important;
}
.success-color {
    color: $SuccessColor;
}
.danger-color {
    color: $DangerColor;
}
.secondarytext-color {
    color: $SecondaryText;
}
//text size
.caption-size {
    font-size: $CaptionFontSize;
    letter-spacing: -0.02px;
}

//size
$sideBarWidth: toRem(250);
$headerHeight: toRem(50);
$listDataWidth: 250px;

//background color for tooltip
$BackgroundColorHoveringContainer: #f2fafd;

$MinHeight: calc(100vh - 175px);

@keyframes animateLoadPage {
    from {opacity: 0}
    to {opacity: 1}
}

//Devive size
$DesktopMediumMaxSize: 1800px;
$DesktopMediumMinSize: 1400px;
