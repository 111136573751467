.btn-default {
    background-color: white !important;
    color: $PrimaryText !important;
    border: solid 1px $BorderColor !important;
    box-shadow: unset !important;
    font-weight: 500;
    height: toRem(40) !important;
    svg {
      fill: $GrayColor2;
      path {
        fill: $GrayColor;
      }
    }
}
.btn-primary, .btn-secondary {
  margin-right: 15px !important;
}
.MuiButtonBase-root.MuiButton-root {
  border-radius: 8px;
  font-weight: 500;
  font-size: $BodyFontSize;
  line-height: 20px;
  padding: 10px 16px;
}

//Will remove if update new ui
.btn-primary {
  background-color: $PrimaryColor !important;
}
.btn-secondary {
  background-color: white !important;
  border: 1px solid $PrimaryColor !important;
  color: $PrimaryColor !important;
  box-shadow: none !important;
  &:hover {
    background-color: rgba(245, 73, 15, 0.04) !important;
  }
}
.MuiButton-root.Mui-disabled {
  background-color: rgba(0, 0, 0, 0.12) !important;
}