@import "styles/scss/variable.scss";
.ListTable {
    margin-top: toRem(35);
    width: 100%;
    box-shadow: 0px 5px 10px 10px rgba(22, 22, 22, 0.1);
    border-radius: toRem(24);
    // margin-bottom: toRem(30);
    .header {
        display: flex;
        justify-content: space-between;
        border-top-left-radius: toRem(24);
        border-top-right-radius: toRem(24);
        align-items: center;
        background-color: white;
        height: toRem(60);
        padding: 0 toRem(16);
        overflow-x: auto;
        overflow-y: hidden;
        .title {
            h2 {
                font-weight: 500;
                font-size: toRem(20);
                line-height: toRem(24);
            }
        }
        .filterGroup {
            display: flex;
            align-items: center;
            gap: toRem(10);
            // width: toRem(350);
            .inputSeach {
                width: toRem(400);
                :global(.group-input-search) {
                    max-width: toRem(400);
                }
            }
            .customWidthInputSearch {
                width: toRem(440);
            }
            .filter {
                width: toRem(40);
            }
            .selectFilter {
                display: flex;
                gap: toRem(16);
                :global(.Select-Wraper), :global(.SelectAutoCompleteWraper), :global(.SelectAutoCompleteWraper) {
                    width: toRem(180);
                }
            }
        }
    }
    .content {
        :global(.table) {
            margin-top: 0;
            box-shadow: none;
            th {
                &:first-of-type {
                    border-top-left-radius: 0px;
                }
                &:last-of-type {
                    border-top-right-radius: 0px;
                }
            }
        }
    }
}