@import 'styles/scss/variable.scss';

.SelectAutoCompleteWraper {
  :global(.MuiAutocomplete-root) {
    :global(.MuiOutlinedInput-root) {
      height: toRem(36);
      box-sizing: border-box;
      align-items: center;
      padding: 0 !important;
      padding-left: toRem(12) !important;
      box-sizing: border-box;

      :global(.MuiAutocomplete-endAdornment) {
        // right: toRem(9);
        position: relative;
        top: 0;
      }

      :global(.MuiAutocomplete-input) {
        // padding: 7.5px 4px 7.5px 6px;
        padding: 0;
      }
    }

    label {
      color: transparent;
      background-color: transparent;
    }

    legend {
      width: 0;
    }
  }

  // :global(.MuiAutocomplete-hasClearIcon) {
  //     :global(.MuiAutocomplete-endAdornment) {
  //         top: toRem(5) !important;
  //     }
  // }
  .label {
    margin-bottom: toRem(12);
  }
}

.SelectAutoCompleteMultiWraper {
  :global(.MuiChip-root) {
    border-radius: toRem(4);
    height: toRem(26);
  }
  :global(.MuiOutlinedInput-root) {
    padding: 0 !important;
    padding-left: toRem(12) !important;
    padding-right: toRem(12) !important;
    box-sizing: border-box;
    align-items: center;
    height: toRem(36);
    :global(.MuiAutocomplete-endAdornment) {
      position: relative;
      top: 0;
      right: toRem(-3) !important;
    }
  }
  :global(.MuiOutlinedInput-root) {
    padding: 0 !important;
    padding-left: toRem(12) !important;
    padding-right: toRem(12) !important;
    box-sizing: border-box;
    align-items: center;
    height: auto;
    :global(.MuiAutocomplete-endAdornment) {
      position: relative;
      top: 0;
      right: toRem(-3) !important;
    }
  }
  :global(.Mui-focused) {
    height: auto !important;
    align-items: center;
  }
}