@import 'styles/scss/variable.scss';
$SidebarBackgroundColor: #232323;
$SidebarBorderColor: rgba(255, 255, 255, 0.12);
$SidebarBackgroundColorSelected: $PrimaryColor;
$SidebarBackgroundColorHover: #333438;
$SidebarCollapseWidth: 74px;
$SidebarTextColor: #f2f2f2;
#sidebar {
    // margin-top: 63px;
    width: $sideBarWidth;
    z-index: 3;
    flex: none;
    min-height: calc(100vh - 50px);
    background-color: $SidebarBackgroundColor;
    .sidebar {
        height: 100%;
        color: $SidebarTextColor;
        padding: 0 toRem(8);
        .menu-items {
            .icon-item {
                color: $SidebarTextColor;
                font-size: $HeaderFontSize;
                min-width: toRem(35);
                svg {
                    width: toRem(20);
                    height: toRem(20);
                }
            }
            .menu-item {
                padding: toRem(10) toRem(12);
                margin-bottom: toRem(2);
                .MuiListItemText-root {
                    color: #ccc;
                    font-weight: 500;
                    @media screen and (min-width: $DesktopMediumMinSize) and (max-width: $DesktopMediumMaxSize) {
                        font-size: 14px;
                    }
                }
                i {
                    color: #666
                }
                &:hover,
                &.active {
                    color: $ContentColor;
                    background-color: $SidebarBackgroundColorSelected;
                    border-radius: toRem(5);
                    .icon-item {
                        color: $ContentColor;
                    }
                    .MuiListItemText-root {
                        color: white;
                    }
                    svg, path {
                        fill: white;
                    }
                    i {
                        color: white;
                    }
                }
            }
            span {
                font-size: $BodyFontSize;
                margin: 2px 0px 0px 0px;
                display: block;
            }
            .groupMenu {
                font-size: toRem(14);
                font-weight: 500;
                color: $SecondaryText;
                padding: toRem(12);
            }
            .lineMenu {
                height: 1px;
                background-color: $SecondaryText;
                padding: 0;
            }
        }
    }
    .logo {
        background-color: $SidebarBackgroundColor;
        min-height: $headerHeight;
        img {
            max-height: toRem(30);
        }
    }
    .sidebar-content {
        overflow-y: auto;
        height: "100%";

        .sidebar-element {
            padding-left: 21px;
            padding-right: 5px;
            position: relative;

            &:hover {
                cursor: pointer;
                background-color: $SidebarBackgroundColorHover;
            }

            .header-icon {
                margin-right: 0px;
                color: $DividerColor;
            }

            .text-element {
                padding-left: 20px;

                span {
                    color: $DividerColor;
                    @extend %BodyMenuFont;
                }
            }

            .collapse-icon {
                color: $DividerColor;
                font-size: 7px;
                position: absolute;
                top: 18px;
                right: 20px;
            }
        }

        .sidebar-element-child {
            @extend .sidebar-element;
            padding-left: 42px;
            span {
                font-weight: normal !important;
            }
        }

        .sidebar-element-selected {
            .header-icon {
                color: $PrimaryColor;
            }

            .text-element {
                span {
                    color: $PrimaryColor;
                }
            }
        }

        .sidebar-element-child-selected {
            .text-element {
                span {
                    color: $PrimaryColor;
                }
            }
        }

        .sidebar-element-hidden {
            display: none;
        }
    }
}