.MuiRadio-root {
  span {
    svg:nth-child(1) {
      width: toRem(22);
      height: toRem(22);
      // color: $BorderColor;
    }

    svg:nth-child(2) {
      display: none;
    }
  }
}

.MuiRadio-root.Mui-checked {
  span {
    svg:nth-child(1) {
      width: toRem(22);
      height: toRem(22);

      path {
        stroke: $PrimaryColor !important;
        stroke-width: toRem(1) !important;
      }
    }
  }
}

//label radio text
.MuiFormGroup-root {
  .MuiFormControlLabel-root .MuiFormControlLabel-label {
    font-size: toRem(14);
  }
}