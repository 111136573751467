@import 'styles/scss/variable.scss';
.App {
  min-width: 1200px;
  :global(#main) {
    display: grid;
    grid-template-columns: toRem(250) calc(100% - 17.85714rem);
    :global(#container) {
      padding-bottom: toRem(64);
    }
  }
}