@import 'styles/scss/variable.scss';
.AddGameShopProductWrapper {
  .addBody {
    display: grid;
    gap: toRem(24);
    grid-template-columns: toRem(1024);
    justify-content: center;
    margin-top: toRem(24);
    margin-bottom: toRem(24);
    .profileContent {
      padding: toRem(24);
      padding-bottom: 0;
    }
    :global(#profileFormId) {
      form {
        padding-bottom: toRem(24);
      }
    }
  }
  .IconFieldWrapper {
    :global(.image-upload-components) {
      border-radius: toRem(16);
    }
  }
  .RadioGroupWrapper {
    gap: toRem(35);
  }
  .uploadImageField {
  }
}