@import 'styles/scss/variable.scss';
.AppVersionWrapper {
  .m-b-20 {
    margin-bottom: 20px;
  }
  padding: 0 2%;
    .verions {
      .addGroupConditionButton {
        border-top: 1px solid $BorderColor;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $PrimaryColor;
        font-size: toRem(14);
        font-weight: 500;
        line-height: toRem(20);
        padding: toRem(16) 0;
        border-bottom-left-radius: toRem(16);
        border-bottom-right-radius: toRem(16);
        cursor: pointer;
        gap: toRem(6);
      }
      .addButtonText {
        display: flex;
        align-items: center;
        color: $PrimaryColor;
        font-size: toRem(14);
        font-weight: 500;
        line-height: toRem(20);
        cursor: pointer;
        gap: toRem(6);
        width: fit-content;
      }

    }
}
.label-title {
    color: #161616;
    font-size: 12px;
    font-weight: 700;
}