@import 'styles/scss/variable.scss';

.AddGameShopCategoryWrapper {
  .formWrapper {
    padding: toRem(24);
    .formContainer {
      display: grid;
      grid-template-columns: auto auto;
      gap: toRem(48);
      background-color: $ContentColor;
      border-radius: toRem(24);
      padding: toRem(24) toRem(24) 0 toRem(24);
      .rightContent {
        textarea {
          height: toRem(72) !important;
        }
      }
    }
  }
}