@import 'styles/scss/variable.scss';

.Collapse {
  .header {
    display: flex;
    position: relative;
    align-items: center;
    padding: toRem(16) toRem(24);
    background-color: $ContentColor;
    border-radius: toRem(24);
    justify-content: space-between;
    .leftHeader {
      display: flex;
      gap: toRem(16);
      align-items: center;
      .icon {
        width: toRem(44);
        height: toRem(44);
        img, svg {
          width: 100%;
          height: 100%;
        }
      }
      .text {
        font-size: toRem(16);
        font-weight: 500;
        line-height: toRem(20);
        color: $PrimaryText;
      }
      .desc {
        font-size: toRem(16);
        line-height: toRem(20);
        color: $PrimaryText;
      }
    }

    .iconCollapse {
      width: toRem(20);
      height: toRem(20);
      position: relative;
      cursor: pointer;
      svg {
        width: toRem(20);
        height: toRem(20);
        // transform: rotate(180deg);
      }
    }
    .removeCollaspe {
      position: absolute;
      right: toRem(70);
      font-size: toRem(14);
      font-weight: 500;
      line-height: toRem(20);
      color: $DangerColor;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: toRem(6);
    }
  }
  .content {
    .childrenContent {
      animation-name: animateLoadPage;
      animation-duration: 2s;
      background-color: $ContentColor;
      border-bottom-left-radius: toRem(24);
      border-bottom-right-radius: toRem(24);
    }
  }
  .opened {
    .header {
      border-bottom: 1px solid $BorderColor;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    // .iconCollapse {
    //   width: toRem(20);
    //   height: toRem(20);
    //   position: relative;
    //   svg {
    //     transform: rotate(0deg);
    //   }
    // }
  }
}