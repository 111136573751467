.group-input-search {
  position: relative;
  background: #fff;
  box-sizing: border-box;
  max-width: 300px;
  height: toRem(36);
  border-radius: toRem(8);
  input {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: toRem(8);
      background-color: #fff;
      padding: 0px 0px 0px toRem(35);
      border: 1px solid $BorderColor;
      box-sizing: border-box;
      &:focus {
          border: 1px solid $PrimaryText;
          outline: 0;
      }
      &:focus-visible {
          outline: 0;
      }
  }
  span {
    i {
      display: none;
    }
      position: absolute;
      top: toRem(8);
      left: toRem(10);
      background-image: url('./searchIcon.svg');
      background-repeat: no-repeat;
      width: toRem(20);
      height: toRem(20);
  }
}