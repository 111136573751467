.MuiCalendarPicker-root {
  @media screen and (min-width: 3000px) {
    div {
      font-size: 14px;
    }
    button {
      font-size: 14px;
      line-height: normal;
    }
    .MuiPickersDay-root {
      font-size: 11px;
    }
    .MuiTypography-caption {
      font-size: 11px;
    }
    svg {
      font-size: 18px !important;
    }
    .MuiPickersArrowSwitcher-button {
      svg {
        font-size: 18px !important;
      }
    }
  }
}